import $ from 'jquery';

$(function () {
    const checkedValue = $("#company_employee input[type='radio']:checked").val();

    if (undefined === checkedValue) {
        showPartialFields();

        return;
    }

    renderFormFields(checkedValue);
});

$('input[type=radio][name="company_employee[authorizationRoles]"]').change(function () {
    renderFormFields(this.value);
});

function renderFormFields(value) {
    if (value === 'ROLE_COMPANY_ADMIN') {
        hideDriverFields();

        return;
    }

    if (value === 'ROLE_DRIVER') {
        showPartialFields();

        return;
    }

    if (value === 'ROLE_BOTH') {
        showPartialFields();

        return;
    }
}

function showPartialFields() {
    $('#company_employee_trailerCapacity').show();
    $('#company_employee_licenseNumber').show();
    $('#company_employee_phoneNumber').show();
    $('#company_employee_priceHidden').show();
    $('#company_employee_email').show();
    $('#company_employee_plainPassword').show();
}

function hideDriverFields() {
    $('#company_employee_email').show();
    $('#company_employee_plainPassword').show();
    $('#company_employee_phoneNumber').hide();
    $('input#company_employee_phoneNumber').val('');
    $('#company_employee_trailerCapacity').hide();
    $('input#company_employee_trailerCapacity').val('');
    $('#company_employee_licenseNumber').hide();
    $('input#company_employee_licenseNumber').val('');
    $('#company_employee_priceHidden').hide();
    $('#company_employee_priceHidden').prop('checked', false);
}

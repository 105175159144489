import 'semantic-ui-css/components/dropdown';
import $ from 'jquery';

$.fn.extend({
    autoComplete() {
        this.each((idx, el) => {
            const element = $(el);
            const criteriaName = element.data('criteria-name');
            const choiceName = element.data('choice-name');
            const choiceValue = element.data('choice-value');

            const selectedValue = element.data('selected-value');
            const selectedName = element.data('selected-name');

            const values = !selectedValue ? false : [{
                name: selectedName,
                value: selectedValue,
                selected: true
            }];

            element.dropdown({
                delay: {
                    search: 250,
                },
                values: values,
                forceSelection: false,
                apiSettings: {
                    dataType: 'JSON',
                    cache: false,
                    beforeSend(settings) {
                        /* eslint-disable-next-line no-param-reassign */
                        settings.data[criteriaName] = settings.urlData.query;

                        return settings;
                    },
                    onResponse(response) {
                        const results = response.map(item => ({
                            name: item[choiceName],
                            value: item[choiceValue],
                        }));

                        if (results.length === 0) {
                            element.dropdown('setup menu', []);
                        }

                        return {
                            success: true,
                            results: results,
                        };
                    },
                },
            });

            window.setTimeout(() => {
                element.dropdown('set selected', element.find('input.autocomplete').val().split(',').filter(String));
            }, 5000);
        });
    },
});

import $ from 'jquery';

function getNotifications() {
    $.ajax({
        type: 'GET',
        url: '/notifications/unread',
        success: function (response) {
            if (response && response.unread === 0) {
                hideNotification();
            } else {
                $('.js-ui-message').remove();
                response.notifications && response.notifications.forEach(function (notification) {
                    addNotification(notification)
                });

                showNotification();
            }
        }
    });
}

function registerReadAllListener() {
    $('.js-mark-all').on('click', function () {
        callMarkAllFunction();
        hideNotification();
    });
}

function registerReadOneListener() {
    $('.js-ui-message').on('click', function (event) {
        const closeIcon = $(event.target);
        const notificationId = closeIcon.data('id');

        if (notificationId === undefined) {
            return;
        }

        callMarkOneFunction(notificationId);
    });
}

function registerClickOnMessageListener() {
    $('.notification-url').on('click', function (event) {
        const message = $(event.target);
        const notificationId = message.data('id');
        $(this).parent().hide();

        if (notificationId === undefined) {
            return;
        }

        callMarkOneFunction(notificationId);
    });
}

function callMarkAllFunction() {
    $.ajax({
        type: 'PUT',
        url: '/notifications/mark-as-read',
        success: function (response) {
            if (response) {
                hideNotification();
            }
        }
    });
}

function callMarkOneFunction(notificationId) {
    $.ajax({
        type: 'PUT',
        url: '/notifications/' + notificationId + '/mark'
    });
}

function addNotification(html) {
    $('#messages-list').append(html);
}

function hideNotification() {
    var notificationMenu = $('#nfq-system-notification');

    $('#notifications').css('display', 'none');
    $('#no-notifications').css('display', 'block');
    notificationMenu.find('.bell.icon').removeClass('yellow').addClass('outline');
}

function showNotification() {
    var notificationMenu = $('#nfq-system-notification');

    $('#notifications').css('display', 'block');
    $('#no-notifications').css('display', 'none');
    notificationMenu.find('.bell.icon').removeClass('outline').addClass('yellow');
}

function registerClickEvent() {
    $('#messages-list').on('click', '.js-ui-message', function (event) {
        var closeIcon = $(event.target);
        var notificationId = closeIcon.data('id');

        if (notificationId === undefined) {
            showNotification();
            return;
        }

        callMarkOneFunction(notificationId);
        closeIcon.parent().hide();
    });
}

export function notificationInitializer() {
    var notificationsDropdown = $('.ui.dropdown.system-notifications-dropdown');

    if (notificationsDropdown.length === 0) {
        return;
    }

    notificationsDropdown.dropdown({action: 'nothing'});

    getNotifications();
    setInterval(getNotifications, 10000);
    registerReadAllListener();
    registerReadOneListener();
    registerClickEvent();
    registerClickOnMessageListener();
}

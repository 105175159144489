import $ from 'jquery';

export function regionSelectResetContact() {
    $('.pickup-region-field').change(function() {
        let $pickupRegionField = $(this);
        let $form = $pickupRegionField.closest('form');
        let $contactAutocomplete = $form.find('[data-nfq-contact-autocomplete-field="pickupContact"]');

        $contactAutocomplete.dropdown('clear');
        $contactAutocomplete.find('input.autocomplete').val('');
        $contactAutocomplete.find('input[type="hidden"]').val('');
    });

    $('.delivery-region-field').change(function() {
        let $deliveryRegionField = $(this);
        let $form = $deliveryRegionField.closest('form');
        let $contactAutocomplete = $form.find('[data-nfq-contact-autocomplete-field="deliveryContact"]');

        $contactAutocomplete.dropdown('clear');
        $contactAutocomplete.find('input.autocomplete').val('');
        $contactAutocomplete.find('input[type="hidden"]').val('');
    });
}

import $ from 'jquery';

const pickupContactOrigin = $('.js-pickup-sale-origin');
const deliveryContactOrigin = $('.js-delivery-sale-origin');
const pickupCompanyButton = $('.js-pickup-company-button');
const deliveryCompanyButton = $('.js-delivery-company-button');
const posterCompanyTemplateVariable = '__POSTER_COMPANY_ID__';
const deliveryCompanyTemplateVariable = '__DELIVERY_COMPANY_ID__';

export function cargoFormInitializer() {
    const inputFields = $('[data-poster-company-field], [data-delivery-company-field]');
    showHideCreateNewCompanyButton(pickupContactOrigin, pickupCompanyButton);
    showHideCreateNewCompanyButton(deliveryContactOrigin, deliveryCompanyButton);

    pickupContactOrigin && pickupContactOrigin.on('change', function (event) {
        showHideCreateNewCompanyButton(pickupContactOrigin, pickupCompanyButton);
    });

    deliveryContactOrigin && deliveryContactOrigin.on('change', function (event) {
        showHideCreateNewCompanyButton(deliveryContactOrigin, deliveryCompanyButton);
    });

    inputFields.each((key, el) => {
        setHrefFromTemplate(el);
        showHideCreateNewCompanyButton(pickupContactOrigin, pickupCompanyButton);
        showHideCreateNewCompanyButton(deliveryContactOrigin, deliveryCompanyButton);
    });

    inputFields.on('change', function(event) {
        setHrefFromTemplate(event.target);
        showHideCreateNewCompanyButton(pickupContactOrigin, pickupCompanyButton);
        showHideCreateNewCompanyButton(deliveryContactOrigin, deliveryCompanyButton);
    });
}

function setHrefFromTemplate(el) {
    const companyId = el.value;
    if (!companyId) {
        return;
    }

    const dataset = el.dataset;
    const isPosterCompany = dataset.hasOwnProperty('posterCompanyField');
    const isDeliveryCompany = dataset.hasOwnProperty('deliveryCompanyField');

    $('[data-href-template]').each(function (key, el) {
        const template = $(el).data('href-template');

        if (isPosterCompany && template.includes(posterCompanyTemplateVariable)) {
            const href = template.replace(posterCompanyTemplateVariable, companyId);
            $(el).attr('href', href);
        }

        if (isDeliveryCompany && template.includes(deliveryCompanyTemplateVariable)) {
            const href = template.replace(deliveryCompanyTemplateVariable, companyId);
            $(el).attr('href', href);
        }
    });
}

function showHideCreateNewCompanyButton(origin, button) {
    if (button.find('a').attr('href') !== undefined && origin.val() !== 'auction') {
        button.show();
    } else {
        button.hide();
    }
}

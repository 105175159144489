export const toggleFormDropdown = () => {
  const dropdownToggles = document.querySelectorAll('.order-information-table-dropdown__toggle td');
  
  dropdownToggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => toggleClassName(e));
  })
};

const toggleClassName = (e) => {
  e.stopPropagation();
  
  e.target.parentNode.classList.toggle('is-active');
};

toggleFormDropdown();

import $ from 'jquery';

function uploadFile(el, event) {
    const input = $(el).find('input[type=file]');
    input.prop('files', event.originalEvent.dataTransfer.files);
    input.trigger('change');
}

$.fn.extend({
    enableDragAndDrop() {
        $(this).off('drop');
        $(this).off('dragover');
        $(this).off('dragleave');

        $(this).on('drop', function(e){
            e.preventDefault();
            $(this).removeClass('hover');

            uploadFile(this, e);
        });
        $(this).on('dragover', function(e){
            e.stopPropagation();
            e.preventDefault();
            $(this).addClass('hover');
        });
        $(this).on('dragleave', function(e){
            e.preventDefault();
            $(this).removeClass('hover');
        });
    },
});

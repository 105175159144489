import $ from 'jquery';

$("#signup-form").submit(function (event) {
    event.preventDefault();

    var form = $(this);
    var url = form.attr('action');

    $.ajax({
        type: 'POST',
        url: url,
        data: form.serialize(),
        statusCode: {
            200: function () {
                location.href = '/verify';
            },
            400: function () {
                form.removeClass('loading');
                $("#phone-not-found-message").removeClass('hidden');
            }
        }
    });
});

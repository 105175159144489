import $ from 'jquery';

function createModal(url) {
    const modal = $('<div class="ui modal"><div class="ui active dimmer"><div class="ui loader"></div></div></div>');
    $('body').append(modal);

    $.ajax({url: url,
        success: function (response) {
            $(modal).html(response);
        },
    });

    modal.modal({
        onHidden: function () {
            $(this).remove();
        },
        onApprove: function() {
            const form = $(modal).find('form')[0];
            const formData = new FormData(form);

            $.ajax({
                url: form.action,
                method: form.method,
                data: formData,
                processData: false,
                contentType: false,
                statusCode: {
                    200: function (response) {
                        $(modal).html(response);
                    },
                    201: function (response) {
                        $(modal).modal('hide');
                    }
                }
            });

            return false;
        }
    }).modal('show');
}

$.fn.extend({
    enableModal() {
        $(this).on('click', function (e) {
            e.preventDefault();

            const url = $(this).attr('href');

            createModal(url);
        });
    },
});

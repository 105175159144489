import $ from 'jquery';

const submitForm = (event, button) => {
    event.preventDefault();
    const form = button.closest('form');
    form.submit();
};

const applyTransitionCallback = (event, actionButton) => {
    event.preventDefault();

    const driverDropdown = $('.driver-dropdown select');

    if (driverDropdown[0] && !driverDropdown[0].value && actionButton.data('transition') === 'assign_to_driver') {
        $('.driver-dropdown').addClass('error');
        $('.driver-validate').removeClass('hidden');
    } else {
        const button = $('#assign-to-company-confirmation-button');
        const confirmationText = $('#confirmation-text');
        const foo = actionButton[0].getAttribute('data-confirmation-title');

        confirmationText.empty();
        confirmationText.append(foo);

        button.off('click');
        button.on('click', () => submitForm(event, actionButton));

        $('#assign-to-company-modal').modal('show');
    }
};

$.fn.extend({
    applyTransitionModal: function () {
        $(this).each(() => {
            $(this).on('click', (event) => applyTransitionCallback(event, $(this)));
        });
    }
});

import $ from 'jquery';

export function copyClipboardSharableLink() {
    $('#copy-clipboard-sharable-link').on('click', function () {
        if (!navigator.clipboard) {
            // Only works on https
            return;
        }

        let copyText = document.getElementById('sharable-link-input');

        copyText.select();
        copyText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyText.value);
    });
}

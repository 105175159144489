import $ from 'jquery';

$(document).on('click', '.js-print-element', function (e) {
    e.preventDefault();
    var $url = $(e.currentTarget).attr('href');
    var $frame = $('<iframe id="js-print-element" src="' + $url + '" style="width:0; height:0; border:0; border:none"></iframe>');

    if ($("#js-print-element").length) {
        $('#js-print-element').replaceWith($frame);
    } else {
        $('body').append($frame);
    }

    $frame.on('load', function () {
        try {
            $frame[0].focus();
            $frame[0].contentWindow.print();
        } catch (e) {
            window.open($url, '_self');
        }
    });
});

import $ from 'jquery';

const value = $('#carrier-payment-amount-suggestion');
const applyBtn = $('#carrier-payment-amount-suggestion-apply-btn');
const pickupSelector = $('[name="cargo[pickupContact]"]');
const deliverySelector = $('[name="cargo[deliveryContact]"]');
const carrierPaymentAmountSelector = $('[name="cargo[carrierPaymentAmount]"]');

let suggestedPrice = null;
let pickupId = pickupSelector.val();
let deliveryId = deliverySelector.val();

export function suggestedPriceInitializer() {

    if (
        value.length === 0
        || applyBtn.length === 0
        || pickupSelector.length === 0
        || deliverySelector.length === 0
    ) {
        return;
    }

    pickupSelector.on('change', function () {
        pickupId = this.value;
        if (pickupId && deliveryId) updateSuggestedAmount(pickupId, deliveryId);
    });

    deliverySelector.on('change', function () {
        deliveryId = this.value;
        if (pickupId && deliveryId) updateSuggestedAmount(pickupId, deliveryId);
    });

    if (pickupId && deliveryId) updateSuggestedAmount(pickupId, deliveryId);

    applyBtn.on('click', function(e) {
        e.preventDefault();
        carrierPaymentAmountSelector.val(suggestedPrice);
    })
}

function updateSuggestedAmount(pickupId, deliveryId) {
    $.ajax({
        url: '/internal-api/cargo/route-price/' + pickupId + '/' + deliveryId,
        type: "GET",
        success: function(response) {
            replaceSuggestedAmount(response.suggestedPrice || '-');
        },
        error: function(xhr, status, error) {
            replaceSuggestedAmount('-');
        }
    });
}

function replaceSuggestedAmount(amount) {
    if (amount === '-') {
        value.html('-');
        suggestedPrice = null;
        applyBtn.hide();

        return;
    }

    value.html('$' + amount);
    suggestedPrice = amount;
    applyBtn.show();
}

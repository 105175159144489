import $ from 'jquery';

export function conditionalRequiredFieldInitializer() {
    if ($('[data-required-if-carrier]')) {
        const companyTypeSelect = $('#company_type');
        if (!companyTypeSelect) {
            return;
        }

        requiredIfCarrier();
        companyTypeSelect.on('change', requiredIfCarrier);
    }
}

function requiredIfCarrier() {
    const companyType = $('#company_type').find(':selected').val();
    const carrierCompanyTypes = ['carrier', 'owner_operator'];
    const fieldBlock = $('[data-required-if-carrier]').parent();

    if (carrierCompanyTypes.includes(companyType)) {
        fieldBlock.addClass('required');
    } else {
        fieldBlock.removeClass('required');
    }
}

import $ from 'jquery';

const displayUploadedImage = function displayUploadedImage(input) {
  if (!input.files) {
    return;
  }

  $(input).parent().siblings('.image').remove();

  Array.prototype.forEach.call(input.files, (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = $('<img class="ui small bordered image"/>');
      img.attr('src', event.target.result);
      $(input).parent().after(img);
    };

    reader.readAsDataURL(file);
  });
};

$.fn.extend({
  previewUploadedImage() {
    $(this).on('change', 'input[type="file"]', function() {
      displayUploadedImage(this);
    });
  },
});

import 'semantic-ui-css/components/accordion';
import 'semantic-ui-css/components/checkbox';
import 'semantic-ui-css/components/dimmer';
import 'semantic-ui-css/components/dropdown';
import 'semantic-ui-css/components/rating';
import 'semantic-ui-css/components/sidebar';
import 'semantic-ui-css/components/tab';
import 'semantic-ui-css/components/transition';
import $ from 'jquery';

import 'sylius/ui/sylius-api-login'
import 'sylius/ui/sylius-api-toggle'
import 'sylius/ui/sylius-bulk-action-require-confirmation'
import 'sylius/ui/sylius-form-collection'
import 'sylius/ui/sylius-prototype-handler'
import 'sylius/ui/sylius-require-confirmation'
import 'sylius/ui/sylius-toggle'

import './company-image-preview'
import './apply-transition-confirmation'
import './signup-form'
import './employee-form'
import './order-information'
import './print-element'
import './contact-form-auto-complete'
import './auto-complete'
import './drag-and-drop'
import './modal'
import './suggested-price'

import {cargoFormInitializer} from './cargo-location-form';
import {notificationInitializer} from './notification';
import {cargoStatusActionInitializer} from "./cargo-status-action";
import {conditionalRequiredFieldInitializer} from "./conditional-required-field";
import {suggestedPriceInitializer} from "./suggested-price";

$(document).ready(() => {
    // ### SYLIUS ###
    $('#sidebar').addClass('visible');
    $('#sidebar').sidebar('attach events', '#sidebar-toggle', 'toggle');
    $('#sidebar').sidebar('setting', {
        dimPage: false,
        closable: false,
    });

    $('.ui.checkbox').checkbox();
    $('.ui.accordion').accordion();
    $('.ui.menu .dropdown').dropdown({ action: 'hide' });
    $('.ui.inline.dropdown').dropdown();
    $('.link.ui.dropdown').dropdown({ action: 'hide' });
    $('.button.ui.dropdown').dropdown({ action: 'hide' });
    $('.ui.fluid.search.selection.ui.dropdown').dropdown();
    $('.ui.tabular.menu .item, .sylius-tabular-form .menu .item').tab();
    $('.ui.card .dimmable.image, .ui.cards .card .dimmable.image').dimmer({ on: 'hover' });
    $('.ui.rating').rating('disable');
    $('.tabular.menu .item').tab();
    $('.ui.selection.dropdown').dropdown();
    
    $('form.loadable button[type=submit]').on('click', (event) => {
        $(event.currentTarget).closest('form').addClass('loading');
    });
    $('.loadable.button').on('click', (event) => {
        $(event.currentTarget).addClass('loading');
    });
    $('.message .close').on('click', (event) => {
        $(event.currentTarget).closest('.message').transition('fade');
    });

    $('[data-requires-confirmation]').requireConfirmation();
    $('[data-bulk-action-requires-confirmation]').bulkActionRequireConfirmation();
    $('[data-toggles]').toggleElement();

    $('.special.cards .image').dimmer({
        on: 'hover',
    });

    $('[data-form-type="collection"]').CollectionForm();
    $('[data-nfq-contact-autocomplete-field]').contactFormAutoComplete();
    $('[data-nfq-autocomplete-field]').autoComplete();

    // ### CUSTOM ###
    $(document).previewUploadedImage();

    $('.js-apply-transition-modal').each(function() {
        $(this).applyTransitionModal();
    });

    notificationInitializer();
    cargoFormInitializer();
    cargoStatusActionInitializer();
    conditionalRequiredFieldInitializer();
    suggestedPriceInitializer();

    $('.ui.accordion').accordion();
    $('[data-upload-box]').enableDragAndDrop();
    $('[data-modal]').enableModal();

    $(document).on('dom-node-inserted', function () {
        $('.ui.accordion').accordion();
        $('[data-upload-box]').enableDragAndDrop();
    });

    $('i.dropdown.icon').on('click', function(e) {
        e.stopPropagation();
        $(this).next().focus();
    });
});

import 'semantic-ui-css/components/dropdown';
import $ from 'jquery';

export function cargoStatusActionInitializer() {
    const form = document.querySelector('[data-cargo-status-action-form]');
    if (!form) {
        return;
    }

    const transitionInput = form.querySelector('[data-transition-field]');
    const companyField = $('[data-company-field]');
    const createCompanyButton = $('[data-create-company-button]');

    if (!transitionInput || !companyField) {
        return;
    }

    companyField.hide();
    createCompanyButton.hide();
    companyField.dropdown('clear');

    transitionInput.addEventListener('change', (event) => {
        const transition = event.target.value;

        if (transition === 'superadmin_assign_to_company') {
            companyField.show();
            createCompanyButton.show();
        } else {
            companyField.hide();
            createCompanyButton.hide();
            companyField.dropdown('clear');
        }
    });
}
